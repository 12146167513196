import { getWwwUrl } from './getWwwUrl';

export const PresentationIframe = ({ seriesId }: { seriesId: string }) => {
  const url = `${getWwwUrl()}/app/presentation/n/${seriesId}/participant`;

  return (
    <iframe
      src={url}
      width="100%"
      height="100%"
      sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
      style={{
        borderWidth: '0px',
      }}
    />
  );
};
