import * as React from 'react';
import {
  AccessibilityIcon,
  EyeIcon,
  type IconT,
} from '@mentimeter/ragnar-visuals';
import {
  AdminIcon,
  AlertIcon,
  PlaySolidIcon,
  StarSolidIcon,
} from '@mentimeter/ragnar-visuals';
import { Box } from '../Box/Box';
import type { BadgeRibbonT } from '../Ribbon/badgeRibbonTypes';

export const BADGE_MAP: {
  [key in BadgeRibbonT]: {
    title: React.FC<IconT> | string;
    description?: string;
  };
} = {
  basic: {
    title: 'Basic',
  },
  pro: {
    title: 'Pro',
  },
  'pro-multi': {
    title: 'Only for teams',
  },
  enterprise: {
    title: 'Enterprise',
  },
  new: {
    title: 'New',
  },
  beta: {
    title: 'Beta',
  },
  upgrade: {
    title: (props: IconT) => <StarSolidIcon {...props} />,
  },
  shared: {
    title: 'Shared',
    description: 'Shared as a template with your organization',
  },
  template: {
    title: 'Template',
    description: 'Shared as a template with your organization',
  },
  example: {
    title: 'Example',
    description: 'Made from an example presentation',
  },
  team: {
    title: 'Team',
  },
  admin: {
    title: (props) => <AdminIcon aria-label="Admin icon" {...props} />,
  },
  soon: {
    title: 'Coming Soon',
  },
  text: {
    title: '',
  },
  'view-only': {
    title: 'View only',
  },
  'view-only-icon': {
    title: (props) => <EyeIcon aria-label="view only" size={1} {...props} />,
  },
  archived: {
    title: 'Archived',
  },
  '50-licenses': {
    title: '+50 licenses',
  },
  warning: {
    title: (props) => <AlertIcon aria-label="Alert icon" size={1} {...props} />,
  },
  play: {
    title: (props) => (
      <Box className="scale-[0.8] translate-x-px">
        <PlaySolidIcon aria-label="Play icon" {...props} />
      </Box>
    ),
  },
  'published-template': {
    title: 'Published template',
  },
  premium: {
    title: 'Premium',
  },
  free: {
    title: 'Free',
  },
  accessibility: {
    title: (props) => <AccessibilityIcon {...props} size={1} />,
  },
};
