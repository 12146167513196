import type { BadgeRibbonT } from '../Ribbon/badgeRibbonTypes';
import { BADGE_MAP } from './badgeMap';

export function badgeLabel(type: BadgeRibbonT, text: string | undefined) {
  const badgeTitle = text || BADGE_MAP[type]?.title;

  return typeof badgeTitle === 'string'
    ? `${badgeTitle} badge`
    : `${type} badge`;
}
