import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { addBreadcrumb } from '@mentimeter/errors';
import { core } from '@mentimeter/http-clients';
import type { InternalStore, SlideState } from './types';
import { getPresentationStateFromPayload } from './state-to-message';
import {
  createInitialState,
  getNextSlideState,
  getNextState,
  getNextStepState,
  getPreviousSlideState,
  getPreviousState,
  getPreviousStepState,
  getResetAllSlideStates,
  getResetStepState,
  getSeriesUpdateState,
  getSetActiveSlideState,
  getSetBlankScreenVisibleState,
  getSetSlideState,
} from './actions';

export const usePresentation = create<InternalStore>()(
  subscribeWithSelector((set, get) => {
    const setWithErrorHandling = (
      setState: (
        state: InternalStore,
      ) => InternalStore | Partial<InternalStore>,
      type: string,
    ) => {
      try {
        set((store) => {
          const state = setState(store);
          addBreadcrumb({
            category: 'usePresentation',
            message: `Setting state from action: ${type}`,
            data: {
              state: state.state ?? {},
              latestUpdate: state.latestUpdate ?? {},
            },
          });
          return { ...state, error: undefined };
        });
      } catch (error) {
        addBreadcrumb({
          type: 'error',
          level: 'error',
          category: 'usePresentation',
          message: `Setting state from action: ${type}`,
          data: get().state ?? {},
        });
        set(() => ({
          error: 'Something went wrong',
        }));
      }
    };

    return {
      state: undefined,
      internalSeries: undefined,
      latestUpdate: undefined,
      error: undefined,
      internalActions: {
        seriesUpdateHandler(series, connectionId) {
          setWithErrorHandling(({ state }) => {
            if (!state) {
              return {
                internalSeries: series,
              };
            }

            return {
              state: getSeriesUpdateState(state, series),
              internalSeries: series,
              latestUpdate: {
                type: 'seriesUpdate',
                placement: undefined,
                connectionId,
              },
            };
          }, 'seriesUpdate');
        },
        async revalidateSeries(id) {
          const { data } = await core().series.getFormattedForPresentation(id);
          get().internalActions.seriesUpdateHandler(data, 'get-request');
        },
        setIncomingState(incomingState, incomingLatestUpdate) {
          const nextState = getPresentationStateFromPayload(incomingState);
          setWithErrorHandling(({ latestUpdate }) => {
            if (incomingLatestUpdate.timestamp === undefined) {
              throw new Error('tried to set state without timestamp');
            }
            const timestampInStore = latestUpdate?.timestamp ?? 0;
            if (incomingLatestUpdate.timestamp <= timestampInStore) {
              // Throw away incoming state since there is a newer version in the store.
              return {};
            }
            return {
              state: nextState,
              latestUpdate: incomingLatestUpdate,
            };
          }, 'setIncomingState');
        },
      },
      selectors: {
        progress() {
          const state = get().state;
          if (!state) return 0;
          return (state.slideIndex + 1) / state.totalSlides;
        },
      },
      actions: {
        async createOrUpdate(seriesId, slidePublicKey) {
          const HALF_HOUR_IN_MS = 30 * 60 * 1000;
          const latestUpdateTimestamp = get().latestUpdate?.timestamp ?? 0;
          const latestUpdateIsNewerThanHalfHour =
            latestUpdateTimestamp - Date.now() < HALF_HOUR_IN_MS;
          const { state } = get();
          if (
            state &&
            latestUpdateIsNewerThanHalfHour &&
            slidePublicKey &&
            slidePublicKey !== state?.slidePublicKey
          ) {
            /* there is a presentation, its no older than 30 minutes, and the slidePublicKey passed differs the one in state. lets update the presentation  */
            await get().internalActions.revalidateSeries(seriesId);
            get().actions.setActiveSlide(slidePublicKey);
            return;
          }
          const { data } =
            await core().series.getFormattedForPresentation(seriesId);
          get().actions.create(data, slidePublicKey);
        },
        create(series, slidePublicKey) {
          setWithErrorHandling(
            () => ({
              state: createInitialState(series, slidePublicKey),
              internalSeries: series,
              latestUpdate: { type: 'createOrUpdate', placement: undefined },
            }),
            'createOrUpdate',
          );
        },
        setActiveSlide(slidePublicKey) {
          setWithErrorHandling(
            ({ state, internalSeries }) => ({
              state: getSetActiveSlideState(
                state,
                internalSeries,
                slidePublicKey,
              ),
              latestUpdate: {
                type: 'setActiveSlide',
                connectionId: undefined,
                placement: undefined,
              },
            }),
            'setActiveSlide',
          );
        },
        resetStep(placement) {
          setWithErrorHandling(
            ({ state, internalSeries }) => ({
              state: getResetStepState(state, internalSeries),
              latestUpdate: {
                type: 'resetStep',
                connectionId: undefined,
                placement,
              },
            }),
            'resetStep',
          );
        },
        next(placement) {
          setWithErrorHandling(
            ({ state, internalSeries }) => ({
              state: getNextState(state, internalSeries),
              latestUpdate: {
                type: 'next',
                placement,
                connectionId: undefined,
              },
            }),
            'next',
          );
        },
        nextSlide(placement) {
          setWithErrorHandling(
            ({ state, internalSeries }) => ({
              state: getNextSlideState(state, internalSeries),
              latestUpdate: {
                type: 'nextSlide',
                placement,
                connectionId: undefined,
              },
            }),
            'nextSlide',
          );
        },
        nextStep(placement) {
          setWithErrorHandling(
            ({ state, internalSeries }) => ({
              state: getNextStepState(state, internalSeries),
              latestUpdate: {
                type: 'nextStep',
                placement,
                connectionId: undefined,
              },
            }),
            'nextStep',
          );
        },
        previous(placement) {
          setWithErrorHandling(
            ({ state, internalSeries }) => ({
              state: getPreviousState(state, internalSeries),
              latestUpdate: {
                type: 'previous',
                placement,
                connectionId: undefined,
              },
            }),
            'previous',
          );
        },
        previousSlide(placement) {
          setWithErrorHandling(
            ({ state, internalSeries }) => ({
              state: getPreviousSlideState(state, internalSeries),
              latestUpdate: {
                type: 'previousSlide',
                placement,
                connectionId: undefined,
              },
            }),
            'previousSlide',
          );
        },
        previousStep(placement) {
          setWithErrorHandling(
            ({ state, internalSeries }) => ({
              state: getPreviousStepState(state, internalSeries),
              latestUpdate: {
                type: 'previousStep',
                placement,
                connectionId: undefined,
              },
            }),
            'previousStep',
          );
        },
        setSlideState(publicKey, slideState) {
          setWithErrorHandling(({ state }) => {
            const currentSlideState = state?.slideStates?.[publicKey] ?? {};
            const nextPartialSlideState =
              typeof slideState === 'function'
                ? slideState(currentSlideState)
                : slideState;
            const nextSlideState = {
              ...currentSlideState,
              ...nextPartialSlideState,
            } as SlideState;
            return {
              state: getSetSlideState(state, publicKey, nextSlideState),
              latestUpdate: {
                type: 'setSlideState',
                placement: undefined,
                connectionId: undefined,
              },
            };
          }, 'setSlideState');
        },
        resetSlideState(publicKey, placement) {
          setWithErrorHandling(({ state }) => {
            return {
              state: getSetSlideState(state, publicKey, {}),
              latestUpdate: {
                type: 'resetSlideState',
                placement,
                connectionId: undefined,
              },
            };
          }, 'resetSlideState');
        },
        resetAllSlideStates(placement) {
          setWithErrorHandling(({ state }) => {
            return {
              state: getResetAllSlideStates(state),
              latestUpdate: {
                type: 'resetAllSlideStates',
                placement,
                connectionId: undefined,
              },
            };
          }, 'resetAllSlideStates');
        },
        setBlankScreenVisible(visible: boolean) {
          setWithErrorHandling(({ state }) => {
            return {
              state: getSetBlankScreenVisibleState(state, visible),
              latestUpdate: {
                type: 'setBlankScreenVisible',
                placement: undefined,
                connectionId: undefined,
              },
            };
          }, 'setBlankScreenVisible');
        },
      },
    };
  }),
);

export const subscribeToPresentationStore = usePresentation.subscribe;
export const getPresentationStore = usePresentation.getState;

/* return the ID of the latest presentation of this user, if it exists */
export const getLatestPresentationId = () => getPresentationStore()?.state?.id;
