const AVATAR_COLORS = [
  'bg-[var(--palette-blue-light-200)]',
  'bg-[var(--palette-pink-dark-100)]',
  'bg-[var(--palette-yellow-light-500)]',
  'bg-[var(--palette-green-light-300)]',
  'bg-[var(--palette-purple-light-200)]',
];

/** Takes a string and hashes it into an integer value. */
function getIntHashFromString(value: string) {
  const randomInt = value
    .split('')
    .reduce(
      (hashCode: number, currentVal: string) =>
        currentVal.charCodeAt(0) +
        (hashCode << 6) +
        (hashCode << 16) -
        hashCode,
      0,
    );

  // Ensure the integer is positive
  return Math.abs(randomInt);
}

export function getAvatarColor(avatarId = '1') {
  const randomInt = getIntHashFromString(avatarId);
  return AVATAR_COLORS[randomInt % AVATAR_COLORS.length]!;
}
