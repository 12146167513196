import * as React from 'react';
import { useRagnar } from '@mentimeter/ragnar-react';
import { useTransition, animated } from 'react-spring';
import { PortalOld, PortalHelper } from '../Portal';
import { Backdrop } from '../Backdrop';

export interface ActionsheetT {
  children: React.ReactNode;
  show: boolean;
  onDismiss?: () => void;
}

export const Actionsheet = ({
  show: shouldShow,
  onDismiss,
  children,
}: ActionsheetT) => {
  const { theme } = useRagnar();
  const { modal: zIndex } = theme.zIndex;
  const transition = useTransition(shouldShow, {
    from: { y: 100, opacity: 0, backdropOpacity: 0 },
    enter: { y: 0, opacity: 1, backdropOpacity: 1 },
    leave: { y: 100, opacity: 0, backdropOpacity: 0 },
  });

  return (
    <PortalOld domId="actionsheet-portal">
      {transition(
        (styles, isShowing) =>
          isShowing && (
            <PortalHelper
              zIndex={zIndex}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                // When the action-sheet is closed, we immediately want to be able to click things behind it
                pointerEvents: shouldShow ? 'auto' : 'none',
              }}
            >
              <animated.div
                role="dialog"
                aria-modal="true"
                aria-live="polite"
                style={{
                  position: 'fixed',
                  zIndex: 2,
                  bottom: 0,
                  maxHeight: '100%',
                  left: '50%',
                  transform: styles.y.to(
                    (y: any) => `translate3d(-50%,${y}%,0)`,
                  ),
                  opacity: styles.opacity,
                }}
              >
                {children}
              </animated.div>
              <Backdrop
                as={animated.div}
                onClick={onDismiss}
                style={{
                  opacity: styles.backdropOpacity as unknown as number,
                }}
              />
            </PortalHelper>
          ),
      )}
    </PortalOld>
  );
};
