import * as React from 'react';
import type { Rule } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';
import { Content } from '@radix-ui/react-dialog';
import type { DialogContentProps } from '@radix-ui/react-dialog';
import type { ComponentProps } from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { variants } from './ModalContent.variants';

type DialogContentSize = 'small' | 'medium' | 'large';

interface DialogContentPrimitiveProps extends DialogContentProps {
  topAligned?: boolean;
  size?: DialogContentSize;
}

const dialogContentRule: Rule<DialogContentPrimitiveProps> = () => ({});

const DialogRadixContentStyled = styled(
  Content,
  {
    displayName: 'DialogRadixContentStyled',
  },
  true,
)(dialogContentRule, 'layout', 'flexbox', 'position', 'color', 'kosmosSpacing');

export interface ModalContentProps
  extends ComponentProps<typeof DialogRadixContentStyled> {
  dismissable?: boolean;
}

export const ModalContent = React.forwardRef(
  (
    {
      dismissable = true,
      onPointerDownOutside,
      onEscapeKeyDown,
      size,
      topAligned,
      className,
      ...props
    }: ModalContentProps,
    forwardedRef,
  ) => {
    const handlePointerDownOutside: DialogContentProps['onPointerDownOutside'] =
      (e) => {
        if (insideToasts(e.target)) e.preventDefault();
        if (!dismissable) e.preventDefault();
        if (onPointerDownOutside) onPointerDownOutside(e);
      };
    const handleEscapeKeyDown: DialogContentProps['onEscapeKeyDown'] = (e) => {
      if (!dismissable) e.preventDefault();
      if (onEscapeKeyDown) onEscapeKeyDown(e);
    };
    return (
      <DialogRadixContentStyled
        ref={forwardedRef}
        aria-describedby={undefined}
        onPointerDownOutside={handlePointerDownOutside}
        onEscapeKeyDown={handleEscapeKeyDown}
        className={cn(className, variants({ size, topAligned }))}
        {...props}
      />
    );
  },
);

function insideToasts(element: any) {
  const toasts = document.getElementById('toasts');
  return toasts?.contains(element);
}
