import React, { useRef } from 'react';
import {
  Form,
  Box,
  Button,
  Text,
  Textarea,
  ErrorMessage,
  Avatar,
} from '@mentimeter/ragnar-ui';
import { useTimeout } from '@mentimeter/react-hooks';
import { createComponent, type IRenderer } from '@mentimeter/ragnar-react';
import { trackEvent, useTrack } from '../../helpers/tracker';
import { submitComment } from '../../api/cfa';
import { useTranslate } from '../localization/Translate';
import { useAppSelector } from '../../redux-hooks';
import { usePresentationState } from '../../presentation-state';
import { getNameInitials } from '../../helpers/getNameInitials';
import { Suggestions } from './Suggestions';
import { Feedback } from './Feedback';

interface StateT {
  comment: string;
  loading: boolean;
  feedback: string | undefined;
  hasError: boolean;
  resetTimeout: number | null;
  height: number | null;
}

const initialState: StateT = {
  comment: '',
  loading: false,
  feedback: undefined,
  hasError: false,
  resetTimeout: null,
  height: null,
};

interface CfaFormT {
  resetState: () => void;
  activePublicKey: string;
}

const keyframes = () => ({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

const rule = (
  { duration = 1600, delay = 0 }: { duration?: number; delay?: number },
  renderer: IRenderer,
) => ({
  animation: `${renderer.renderKeyframe(
    keyframes,
    {},
  )} ${duration}ms ${delay}ms `,
});

const NameField = ({ initials, name }: { initials: string; name: string }) => {
  return (
    <Box
      py="space2"
      flexDirection="row"
      gap="space2"
      alignItems="center"
      maxWidth="100%"
    >
      <Avatar initials={initials} type="user" name={name} />
      <Text
        maxWidth="100%"
        extend={() => ({
          overflowWrap: 'break-word',
        })}
        pr="space12"
      >
        {name}
      </Text>
    </Box>
  );
};

export const CfaForm = ({ resetState, activePublicKey }: CfaFormT) => {
  const translate = useTranslate();
  const trackVoter = useTrack();

  const ref = useRef<HTMLDivElement | null>(null);
  const voteKey = useAppSelector((state) => state.series.vote_key);

  const { participantIdentity } = usePresentationState();

  const [state, setState] = React.useState<StateT>(initialState);
  const [emptyMessageError, setEmptyMessageError] = React.useState(false);

  useTimeout(resetState, state.resetTimeout);

  const handleTextInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState((prev) => ({
      ...prev,
      comment: e.target.value,
    }));
  };

  const handleSelectedSuggestion = (suggestion: string) => {
    setState((prev) => ({ ...prev, comment: suggestion }));
    onSubmit(suggestion);
  };

  const onSubmit = async (c?: string) => {
    if (!activePublicKey) return;
    const comment = c || state.comment;
    setState(
      (prev): StateT => ({
        ...prev,
        loading: true,
        height: ref.current ? ref.current.clientHeight : 0,
      }),
    );
    try {
      const response = await submitComment(activePublicKey, {
        vote_key: voteKey,
        comment,
        should_identify: participantIdentity.status === 'is_identified',
      });
      if (response && response.status === 201) {
        setState(
          (prev): StateT => ({
            ...prev,
            feedback: translate('cfa.comment_sent'),
            hasError: false,
            resetTimeout: 4000,
            loading: false,
          }),
        );
        trackVoter({
          event: 'Sent comment',
          properties: { 'comment type': c ? 'Suggestion' : 'Free text' },
        });
        trackEvent('CFA', 'Comment sent', c ? 'Suggestion' : 'Free text');
      } else {
        setState(
          (prev): StateT => ({
            ...prev,
            feedback: translate('cfa.submit_error'),
            hasError: true,
            loading: false,
          }),
        );
      }
    } catch (e) {
      setState((prev) => ({
        ...prev,
        feedback: translate('cfa.submit_error'),
        hasError: true,
        loading: false,
      }));
    }
  };

  const Animation = createComponent(rule, 'div');

  const describedByIds = [
    state.hasError && 'error-msg-feedback',
    emptyMessageError && !state.comment && 'error-msg-empty-field',
  ]
    .filter((id) => id)
    .join(' ');

  return (
    <Box
      ref={ref}
      pt="space14"
      pb="space10"
      px="space8"
      width="100%"
      minHeight={state.height}
    >
      {state.feedback && !state.hasError ? (
        <Box width="100%" mb="space4" flex="1 1 auto">
          <Box width="100%" mb="space8">
            <Animation>
              <Text
                fontSize="125"
                color="text"
                lineHeight="none"
                fontWeight="regular"
              >
                {state.feedback}
              </Text>
            </Animation>
          </Box>

          {state.comment && (
            <Feedback>
              <Box
                bg="neutralWeak"
                borderRadius="full"
                py="space4"
                px="space6"
                maxWidth="250px"
              >
                <Text color="onNeutralWeak">{state.comment}</Text>
              </Box>
            </Feedback>
          )}
        </Box>
      ) : (
        <Box alignItems="stretch" width="100%">
          <Box width="100%" flex="1 1 auto" pb="space6">
            <Text
              color="text"
              fontSize="125"
              lineHeight="snug"
              fontWeight="regular"
            >
              {translate('cfa.comment')}
            </Text>
          </Box>

          <Suggestions selectSuggestion={handleSelectedSuggestion} />

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (state.loading) return;
              if (state.comment) {
                onSubmit();
              } else {
                setEmptyMessageError(true);
              }
            }}
            width="100%"
          >
            <Box width="100%" alignItems="center">
              <Box width="100%" pt="space6" pb="space5" alignItems="stretch">
                {participantIdentity.status === 'is_identified' && (
                  <NameField
                    initials={getNameInitials(participantIdentity.name, 1)}
                    name={participantIdentity.name}
                  />
                )}

                {participantIdentity.status === 'series_is_anonymous' && (
                  <NameField
                    initials=""
                    name={translate('identified_responses.anonymous')}
                  />
                )}
                <Textarea
                  autoComplete="off"
                  id="cfa-form-input"
                  name="question"
                  maxLength={100}
                  rows={3}
                  maxRows={3}
                  value={state.comment}
                  minHeight="128px"
                  status={
                    emptyMessageError && !state.comment ? 'error' : undefined
                  }
                  onChange={(e) => {
                    setEmptyMessageError(false);
                    handleTextInput(e);
                  }}
                  placeholder={translate('cfa.write_comment')}
                  aria-describedby={describedByIds || undefined}
                />

                {state.hasError && (
                  <ErrorMessage id="error-msg-feedback" mt="space2">
                    {state.feedback}
                  </ErrorMessage>
                )}
                {emptyMessageError && !state.comment && (
                  <ErrorMessage id="error-msg-empty-field" mt="space2">
                    Empty field. Please enter a comment and retry.
                  </ErrorMessage>
                )}
              </Box>

              <Button
                variant="primary"
                size="large"
                state={state.loading ? 'loading' : undefined}
                type="submit"
              >
                {state.hasError
                  ? translate('buttons.try_again')
                  : translate('cfa.submit_comment')}
              </Button>
            </Box>
          </Form>
        </Box>
      )}
    </Box>
  );
};
